var useReviewInputValidation = function useReviewInputValidation() {
  var chars = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var score = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    _ref$maxChars = _ref.maxChars,
    maxChars = _ref$maxChars === void 0 ? 1000 : _ref$maxChars,
    _ref$minWords = _ref.minWords,
    minWords = _ref$minWords === void 0 ? 2 : _ref$minWords;
  var charsSplitted = chars.split(' ');
  var colorError = 'text.error';
  var colorDefault = 'text.light';
  if (chars.length === 0 && score <= 3) {
    return {
      message: "Este campo precisa ser preenchido",
      textColor: colorError,
      valid: false
    };
  }
  if (charsSplitted.length >= minWords && chars.length <= maxChars) {
    return {
      message: "".concat(maxChars - chars.length, " caracteres restantes"),
      textColor: colorDefault,
      valid: true
    };
  }
  if (chars.length > 0 && charsSplitted.length < minWords) {
    return {
      message: "O coment\xE1rio deve ter ao menos 2 palavras",
      textColor: colorError,
      valid: false
    };
  }
  if (chars.length > maxChars) {
    return {
      message: "Voc\xEA atingiu o tamanho m\xE1ximo do coment\xE1rio",
      textColor: colorError,
      valid: false
    };
  }
  return {
    message: "M\xE1ximo de ".concat(maxChars, " caracteres"),
    textColor: 'text.light',
    valid: true
  };
};
export default useReviewInputValidation;